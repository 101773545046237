<template>
    <div>
        <iframe :src="frame" width="100%" height="1200px" frameborder="0"></iframe>
    </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import { jsPDF } from 'jspdf'
import { useRouter } from '@core/utils'
import font from '@/components/prints/fontKR.json'
import fontBold from '@/components/prints/fontBoldKR.json'
import { formatPrice, sumdate } from '@/services/filters'
import { api } from "@/services/api"

export default {
    setup() {
        const { route } = useRouter()
        const fonts = ref(font)
        const fontsBold = ref(fontBold)
        const fontTitle = ref(16)
        const fontBody = ref(14)
        const lastTable = ref(0)
        const shopData = ref({})
        const frame = ref('')

        onMounted(() => {
            shopData.value = JSON.parse(localStorage.getItem('shop'))
            api.get({
                path: '/printTransportation',
                param: `?order_id=${ route.value.query.id.split(',') }&lang=${ route.value.query.lang }&currency_id=${ localStorage.getItem('currencyId') }`,
            }).then(({ data }) => {
                savePDF(data)
            }).catch((err) => {
                console.log(err)
            })
        })

        const savePDF = data => {
            const doc = new jsPDF({
                orientation: 'landscape',
                unit: 'mm',
                format: 'a5',
            })

            doc.setProperties({
                title: `ใบส่งของ`,
            })

            doc.addFileToVFS('-23-normal.ttf', fonts.value.data)
            doc.addFont('-23-normal.ttf', '-23', 'normal')
            doc.addFileToVFS('-23-bold.ttf', fontsBold.value.data)
            doc.addFont('-23-bold.ttf', '-23', 'bold')
            doc.setFont('-23', 'bold')

            for (let i = 0; i < data.length; i++) {
                let spacer = 10
                let currentY = 25
                let currentX = 15
                let spacer1 = 10
                let currentY1 = 90
                let currentX1 = 195

                doc.setFontSize(22)
                doc.text('보내는 사람의 이름과 주소 : ', currentX, 15)
                doc.setFontSize(fontTitle.value)
                doc.text(`보낸 사람 이름 : ${ data[i].order_transportation_name }`, currentX, currentY)
                spacer = 8
                currentY += spacer
                doc.text(`전화번호1 : ${ data[i].order_transportation_phone }`, currentX, currentY)
                currentY += spacer
                doc.text(`주소 : ${ data[i].order_transportation_address } ${ data[i].order_transportation_subdistrict } ${ data[i].order_transportation_district }`, currentX, currentY)
                currentY += spacer
                doc.text(`${ data[i].order_transportation_province } ${data[i].shop_zipcode}`, 15, currentY)

                //--------------------------------------------------

                doc.setFontSize(22)
                doc.text('받는사람 주소', currentX1, 80, null, null, 'right')
                doc.setFontSize(fontTitle.value)
                doc.text(`받는사람 : ${ data[i].order_transportation_name }`, currentX1, currentY1, null, null, 'right')
                spacer = 8
                currentY1 += spacer
                doc.text(`전화번호1 : ${ data[i].order_transportation_phone }`, currentX1, currentY1, null, null, 'right')
                currentY1 += spacer
                doc.text(`주소 : ${ data[i].order_transportation_address } ${ data[i].order_transportation_subdistrict } ${ data[i].order_transportation_district }`, currentX1, currentY1, null, null, 'right')
                currentY1 += spacer
                doc.text(`${ data[i].order_transportation_province } ${data[i].order_transportation_zipcode}`, currentX1, currentY1, null, null, 'right')
                doc.addPage(1)
            }
            doc.deletePage(data.length + 1)
            frame.value = doc.output('datauristring', { filename: `Hello-world.pdf` })
        }

        return { savePDF, frame }
    },
}
</script>
